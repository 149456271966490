.scene {
  background-color: #f3f0e7;
  display: flex;
  flex-direction: row;
  position: relative;
}

.scene.quote .story {
  font-style: italic;
  padding: 25vh 0;
}

.all h1,
.all p,
.all a {
  color: rgb(20, 40, 120);
}

.all p {
  font-weight: 400;
}

.description {
  padding: 2rem 4rem;
  margin: 0 auto;
  max-width: 560px;
  height: 100%;
}

.description h1 {
  text-align: left;
  font-size: 32px;
  line-height: 1.2;
}

.description .story {
  height: auto;
  padding-bottom: 15vh;
  position: relative;
  overflow-y: auto;
}

.story a {
  color: fuchsia;
  font-weight: bold;
  margin: 0;
  position: relative;
  z-index: 9000;
}

.scene .sidebar {
  bottom: 12px;
  height: 12.5vh;
  padding: 0;
  position: fixed;
  width: 15vw;
  left: 0;
}

.scene .sidebar .button--apps {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.scene .sidebar .button--apps img {
  display: inline-block;
  height: 90px;
  margin: 0 auto;
  vertical-align: middle;
  width: auto;
}

.scene .sidebar .button--notes img {
  height: 80px;
}

.scene .sidebar .button--camera img {
  height: 60px;
}

.scene .App {
  background: #f3f0e7;
  height: 100vh;
  margin: 0 0 0 auto;
  padding: 0;
  position: relative;
  width: 100vw;
}

.links {
  align-items: flex-end;
  background-color: #f3f0e7;
  border-top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  min-height: 8rem;
  padding: 1rem 0 1rem 16vw;
  position: fixed;
  text-align: right;
  width: 100%;
}

.links:before {
  background: linear-gradient(
    to top,
    rgb(243, 240, 231) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  display: block;
  height: 40px;
  position: absolute;
  top: -4rem;
  left: 0;
  width: 100%;
}

.links a {
  color: #0b6ebd;
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}

.links a + a {
  margin-top: 0.6rem;
}

.links a:before,
.links a:after {
  background: #0b6ebd;
  border-radius: 4px;
  content: "";
  display: block;
  height: 2px;
  left: auto;
  position: absolute;
  right: -12px;
  top: 16px;
  transform: rotate(135deg);
  width: 10px;
}

.links a:before {
  top: 10px;
  transform: rotate(45deg);
}

.arrow {
  background: transparent;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 20%;
}

.arrow:before,
.arrow:after {
  background: rgb(195, 189, 178);
  border-radius: 4px;
  content: "";
  display: block;
  height: 4px;
  left: 4px;
  position: absolute;
  top: calc(50% - 35px);
  transform: rotate(135deg);
  width: 24px;
}

.arrow:after {
  top: calc(50% - 20px);
  transform: rotate(45deg);
}

.next-arrow {
  left: auto;
  right: 0;
}

.next-arrow:after,
.next-arrow:before {
  left: auto;
  right: 4px;
}

.next-arrow:after {
  top: calc(50% - 35px);
  transform: rotate(45deg);
}

.next-arrow:before {
  top: calc(50% - 20px);
  transform: rotate(135deg);
}

@media (max-width: 960px) {
  .scene .sidebar .button--apps img {
    height: 54px;
  }

  .scene .sidebar .button--notes img {
    height: 44px;
  }

  .scene .sidebar .button--camera img {
    height: 34px;
  }
}
