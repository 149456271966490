.app--texts .app--body {
  min-height: 100vh;
  padding-top: 2rem;
}

ul.list--results li.item--text {
  background-color: #fff;
  border-bottom: 1px solid #737373;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
}

.text--link {
  color: #737373;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  min-height: 5rem;
  justify-content: space-between;
}

li.item--text.active .text--link {
  color: #373737;
}

.text--title {
  color: black;
  font-weight: bold;
}

.text--date {
  font-size: 11px;
}

ul.list--results .text--excerpt {
  align-content: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.app--text .container {
  padding: 1rem 2rem 0;
}

.app--text h1 {
  color: #373737;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  text-align: left;
}

.list--messages {
  list-style-type: none;
  margin: 1rem 0 0;
  padding: 0;
}

.list--messages li {
  clear: both;
  display: block;
  font-size: 14px;
  float: left;
  line-height: 1.4;
  margin: 0 auto 2rem 0;
  max-width: 86%;
  width: auto;
}

.list--messages li p {
  margin-bottom: 0;
}

.list--messages li div {
  background-color: #fef26b;
  border-radius: 6px;
  padding: 1rem 1.4rem 1.2rem;
}

.list--messages li:nth-child(even) {
  background-color: #b5ffee;
  float: right;
  margin: 0 0 2rem auto;
}

.list--messages li:nth-child(even) div {
  background-color: #b5ffee;
}

.app--text.visible {
  transition: transform 0.25s;
  transform: translateX(0);
}

.app--text.hidden {
  transform: translateX(100%);
  width: 1px;
}