header {
  background-color: #000;
  border-radius: 12px 12px 0 0;
  color: white;
  height: 2.4rem;
  left: 0;
  line-height: 2.4rem;
  margin: 0 auto;
  max-width: 560px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 10;
}

header span {
  display: none;
  font-size: 14px;
}

header.text--center {
  text-align: center;
}

header.text--center span {
  display: inline-block;
  transform: translateX(50px);
}

header .container {
  max-width: 100vw;
  padding: 0 1rem;
}

.topbar {
  height: 1rem;
  float: right;
  margin-top: 6px;
}

.button--close {
  display: block;
  border: none;
  color: white;
  font-size: 26px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  top: -2px;
  left: 10px;
  height: 32px;
  width: 32px;
  text-align: center;
}

.button--close:hover {
  text-decoration: none;
}

.app--body {
  padding-top: 6rem;
}

/* Portrait */
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  header {
    border-radius: 0;
    max-width: 100%;
  }
}

/* Landscape */
@media only screen and (max-device-width: 668px) and (orientation: landscape) {
  header {
    border-radius: 0;
    max-width: 100%;
  }
}
