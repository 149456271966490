.spacebook {
  background: #edeef2;
  font-size: 18px;
  line-height: 1.4;
  height: 100vh;
  position: relative;
}

.spacebook-header {
  align-items: center;
  background-color: #5b6e9a;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  left: 0;
  margin: 0 auto;
  max-width: 560px;
  padding-left: 1rem;
  position: absolute;
  top: 2rem;
  width: 100%;
  z-index: 10;
}

.spacebook .qlick-logo img {
  max-height: 30px;
}

.spacebook a {
  text-decoration: none;
}

.spacebook main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 6.6rem;
}

.spacebook footer {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.spacebook .sidebar {
  width: auto;
}

.spacebook .feed {
  padding-top: 1rem;
  width: 100%;
}

.spacebook h1 {
  font-size: 7rem;
  font-weight: 200;
}

.spacebook h2 {
  font-size: 1.8rem;
  line-height: 1;
  margin: 0 0 1.6rem 0;
}

.spacebook p {
  margin: 0;
}

.spacebook img {
  display: block;
  max-width: 100%;
}

.spacebook nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.spacebook nav li {
  display: inline-block;
  line-height: 2.4rem;
  margin-left: 2rem;
}

.spacebook nav a {
  color: #fff;
  display: inline-block;
  font-size: 1.4rem;
  margin: 0;
  position: relative;
  text-decoration: none;
  top: -5px;
}

.spacebook nav img {
  display: inline-block;
  margin-right: 0.8rem;
  width: 24px;
}

.spacebook nav .icon__friends {
  width: 18px;
}

.spacebook header .logo {
  display: inline-block;
  max-width: 2.6rem;
  padding-top: 0.6rem;
}

.spacebook header span {
  color: white;
}

.spacebook header span,
.spacebook footer p {
  display: inline-block;
  font-size: 1.2rem;
  margin-left: 0.6rem;
  transform: translateY(-8px);
}

.spacebook .cards {
  margin-bottom: 0;
  margin-top: 7rem;
  overflow-y: auto;
  height: calc(100vh - 80px);
}

.spacebook .cards + .cards {
  border-top: 1px solid #5b6e9a;
  margin-top: 2rem;
  padding-top: 2rem;
}

.spacebook .card {
  background-color: white;
  font-size: 1.6rem;
  padding: 2rem;
}

.spacebook .card p {
  color: #424344;
}

.spacebook .card + .card {
  margin-top: 1.6rem;
}

.spacebook .card__signature-img {
  border-radius: 50%;
  float: left;
  margin-right: 0.8rem;
  height: 3.4rem;
  width: 3.4rem;
}

.card__signature.row {
  font-size: 12px;
}

.card__signature .col-33 {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card__signature .col-25:first-child {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.card__signature .col-25 .card__signature-img {
  float: none;
  height: 72px;
  margin: 0 auto;
  width: 72px;
}

.card__signature .col-75 {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 90px;
  justify-content: space-between;
}

.spacebook .card__name {
  color: #5b6e9a;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
}

.card__signature .col-25 .card__name {
  text-align: center;
}

.spacebook .card__tag {
  color: #5b6e9a;
  font-size: 1.2rem;
}

.spacebook .card__content {
  padding: 1.6rem 0 0;
}

.spacebook .card__content p {
  margin-top: 0.4rem;
}

.spacebook .add--post {
  background-color: #5b6e9a;
  padding: 4px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.add--post button {
  font-size: 14px;
  padding-top: 5px;
}

.spacebook .card__content p.hashtags {
  color: #5b6e9a;
}

.space-search {
  display: block;
  max-width: 80%;
  margin: 0 auto 2rem;
}
