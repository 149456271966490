.qliqk {
  background: #fff;
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 6rem;
}

.qliqk-header {
  align-items: center;
  background-color: #f5f2f2;
  display: flex;
  height: 4rem;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 2rem;
  width: 100%;
  z-index: 10;
}

.qlick-logo {
  max-height: 75%;
}

.qliqk main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 6.6rem;
}

.qliqk footer {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.qliqk .sidebar {
  left: 2rem;
  position: fixed;
  top: 72px;
  width: 14rem;
}

.qliqk .container {
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
  max-width: 1200px;
}

.qliqk .feed {
  transform: translateY(70px);
  padding: 0;
}

.qliqk h1 {
  font-size: 7rem;
  font-weight: 200;
}

.qliqk h2 {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 1.6rem 0;
}

.qliqk p {
  font-weight: 400;
  margin: 0;
}

.qliqk img {
  display: block;
  max-width: 100%;
}

.qliqk nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.qliqk nav li {
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
}

.qliqk nav a {
  color: #373345;
  display: inline-block;
  font-size: 1.4rem;
  position: relative;
  text-decoration: none;
  top: -5px;
}

.qliqk nav img {
  display: inline-block;
  margin-right: 0.8rem;
  width: 24px;
}

.qliqk nav .icon__friends {
  width: 18px;
}

.qliqk header .logo {
  display: inline-block;
  height: 3.4rem;
  padding-top: 0.6rem;
}

.qliqk header span {
  color: white;
}

.qliqk header span,
.qliqk footer p {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.6rem;
  transform: translateY(-8px);
}

.qliqk .cards {
  margin-bottom: 6rem;
}

.qliqk .card {
  font-size: 1.4rem;
}

.qliqk .card>div {
  background: white;
  padding: 2rem;
}

.qliqk .card p {
  color: #424344;
}

.qliqk .card {
  margin-bottom: 1.6rem;
}

.qliqk .card__signature {
  line-height: 1;
}

.qliqk .card__signature-img {
  border-radius: 50%;
  float: left;
  height: 36px;
  margin-right: 0.8rem;
}

.qliqk .card__name {
  margin: 0;
  font-size: 16px;
}

.qliqk .card__tag {
  color: #4a74e2;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
}

.qliqk .card__content {
  padding: 1.6rem 0 0;
}

.qliqk .add--post {
  background-color: #f5f2f2;
  padding: 4px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.qliqk input[type="submit"],
.qliqk button {
  background: #f5f2f2;
  border-color: #f5f2f2;
  color: #373737;
}

.qliqk textarea,
.qliqk input[type="text"],
.qliqk input[type="password"],
.qliqk input[type="search"] {
  border-color: #f5f2f2;
}

@media (max-width: 560px) {
  .qliqk main {
    display: block;
  }

  .qliqk main h2,
  .qliqk nav,
  .qliqk nav li {
    display: inline-block;
    margin-right: 2rem;
  }

  .qliqk main h2 {
    position: relative;
    top: -0.4rem;
  }

  .qliqk .sidebar,
  .qliqk .feed {
    padding: 0;
    position: static;
    width: 100%;
  }
}