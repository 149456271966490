.app--notes .app--body {
  min-height: 100vh;
  padding-top: 2rem;
}

.app--notes header .container {
  margin: 0;
  max-width: 100vw;
  position: relative;
}

.app--notes header .button--close {
  position: absolute;
}

.app--note .container {
  padding: 2rem;
}

.app--note h1 {
  color: #373737;
  display: inline-block;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  text-align: left;
}

.app--sidebar.visible {
  transition: transform 0.25s;
  transform: translateX(0);
}

.app--note.visible {
  transition: transform 0.25s;
  transform: translateX(0);
}

.app--sidebar.hidden {
  transform: translateX(-100%);
  width: 1px;
}

.app--note.hidden {
  transform: translateX(100%);
  width: 1px;
}