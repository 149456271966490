.app-wrapper {
  border-radius: 12px;
  height: 98vh;
  margin: 0 auto;
  max-width: 560px;
  overflow: hidden;
  position: relative;
}

/* Portrait */
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  .app-wrapper {
    border-radius: 0;
    height: 100vh;
    max-width: 100%;
  }
}

/* Landscape */
@media only screen and (max-device-width: 668px) and (orientation: landscape) {
  .app-wrapper {
    border-radius: 0;
    height: 100vh;
    max-width: 100%;
  }
}
