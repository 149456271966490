.screen--home {
  background-size: cover;
}

.screen--home .App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.screen--home .App h1 {
  color: white;
  font-weight: bold;
  margin-bottom: 6vh;
  text-transform: uppercase;
}

.screen--home .App>div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.screen--home a {
  color: white;
}

.button--home {
  background: rgba(255, 255, 255, 0.22);
  border: 4px solid white;
  margin: 0 0 2rem 0;
  padding: 1rem 0;
  width: 33%;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.33s;
}

.button--home:hover {
  background: white;
  color: #4a74e2;
  text-decoration: none;
}