.has-image.screen--apps {
  background-position: left top;
  background-size: cover;
  border-radius: 12px;
  margin: 0 auto;
  max-width: 560px;
  position: relative;
}

.has-image .App.screen {
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  max-width: 560px;
  padding: 0;
  position: relative;
  width: 100vw;
}

.screen--apps .apps {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 86vh;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 560px;
  padding: 1vh 1vw 2vh;
  width: 100vw;
}

.screen--apps .button--apps {
  align-items: center;
  background: transparent;
  display: flex;
  height: 25%;
  justify-content: center;
  margin: 0;
  text-align: center;
  width: 33%;
}

.screen--apps .sidebar {
  background: rgba(12, 58, 180, 0.4);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-evenly;
  height: 12vh;
  margin: 0 auto;
  max-width: 560px;
  padding: 1.4vh 0 0;
  width: 100vw;
}

.screen--apps .sidebar .button--apps {
  display: block;
  height: 10vh;
  margin: 0;
  width: 33%;
}

.is--app a.button.button--home {
  position: absolute;
  top: 12px;
  left: 10px;
}

.button--apps img {
  width: 10vh;
  height: 10vh;
}

@media (min-width: 680px) {
  .button--apps img {
    max-width: 90px;
  }
}

@media (min-width: 1024px) {
  .has-image.screen--apps {
    background-position: center -240px;
  }
  .screen--apps .apps {
    height: 84vh;
  }

  .screen--apps .button--apps {
    height: 33%;
    width: 25%;
  }

  .screen--apps .sidebar {
    height: 16vh;
  }
}

/* Portrait */
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  .has-image.screen--apps,
  .has-image .App.screen,
  .screen--apps .apps,
  .screen--apps .sidebar {
    border-radius: 0;
    max-width: 100%;
  }

  .screen--apps .apps {
    height: 88vh;
  }
}

/* Landscape */
@media only screen and (max-device-width: 668px) and (orientation: landscape) {
  .has-image.screen--apps,
  .has-image .App.screen,
  .screen--apps .apps,
  .screen--apps .sidebar {
    border-radius: 0;
    max-width: 100%;
  }

  .has-image .App.screen {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }

  .screen--apps .button--apps {
    height: 33%;
    width: 25%;
  }

  .button--apps img {
    width: 14vh;
    height: 14vh;
  }

  .screen--apps .apps {
    height: 100vh;
    padding: 7vh 1vw 2vh;
    width: 88vw;
  }

  .screen--apps .sidebar {
    flex-direction: column;
    height: 100vh;
    padding: 3rem 0 0;
    width: 12vw;
  }

  .screen--apps .sidebar .button--apps {
    align-content: center;
    display: flex;
    height: 33%;
    justify-content: center;
    width: 100%;
  }
}
