.app--article {
  background: #eee;
  color: #373737;
  min-height: 100vh;
  padding-bottom: 6rem;
}

.article--body h1,
.article--body p {
  color: #373737;
}

.app--article h1 {
  margin-top: 1rem;
  text-align: left;
  text-transform: uppercase;
}

.app--article p {
  font-size: 16px;
  line-height: 1.6;
}

.app--body.article .button--back {
  color: white;
  position: fixed;
  top: 7px;
  left: 20px;
  text-decoration: none;
  z-index: 10;
}

.app--article small {
  color: #716167;
  display: inline-block;
  font-size: 10px;
}

.app--article .component--wiki {
  background: white;
  border: 1px solid #716167;
  margin-top: 4rem;
  padding: 1rem 3rem 2rem;
}

.app--article .component--wiki p {
  color: #716167;
}
