.app--allseeing.app--body {
  background-color: transparent;
  background-image: url(./images/bg1.png);
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  justify-content: center;
}

.app--allseeing.app--body2 {
  background-image: url(./images/bg2.png);
  min-height: 100vh;
  min-width: 100vw;
  mix-blend-mode: hard-light;
}

.sphere {
  background-image: url(./images/sphere1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin: auto;
  min-height: 50vh;
  height: 50vh;
  will-change: transform;
  width: 50vh;
  animation-duration: 6s;
  animation-name: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.sphere2 {
  background-image: url(./images/sphere2.png);
  animation-name: rotate;
  mix-blend-mode: hard-light;
  opacity: 0.8;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes reverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}