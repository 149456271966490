ul.list--results .list--excerpt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

ul.list--results li.item--list {
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
}

.list--link {
  color: #373737;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  min-height: 5rem;
  justify-content: space-between;
}

.list--title {
  font-weight: bold;
}

.list--title:after {
  content: '';
  display: block;
  width: 100%;
  background-color: #65EECF;
  height: 2px;
}

.list--date {
  font-size: 11px;
}

.item-color-blue .list--title:after {
  background-color: #4A74E2;
}

.item-color-pink .list--title:after {
  background-color: #FF49DC;
}