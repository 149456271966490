.app--questions .logo {
  margin-top: 4rem;
  max-width: 240px;
}

ul.list--results {
  list-style-type: none;
  margin: 0 0 4rem 0;
  padding: 0;
}

ul.list--results li {
  margin: 0;
  padding: 1rem 0;
}

ul.list--results span {
  display: block;
}

.item--result .title {
  color: #1fad8f;
  font-size: 16px;
  font-weight: 600;
}

.item--result .text {
  font-size: 14px;
}

.item--result a.url {
  color: #f45e83;
  font-size: 14px;
}

.app--questions input[type="text"] {
  border-color: #1fad8f;
  display: block;
  margin: 2rem auto;
  max-width: 360px;
}

.app--questions input[type="submit"],
.app--questions button {
  background-color: #f45e83;
  border-color: #f45e83;
  padding: 2px 0 1px;
  width: 240px;
}

.app--questions button {
  font-size: 12px;
}

.app--questions .results .logo {
  display: inline-block;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 25%;
}

.app--questions .results form {
  display: inline-block;
  margin-left: 2%;
  max-width: 72.5%;
  transform: translateY(-16px);
  width: 100%;
}

.app--questions .results input[type="text"] {
  display: inline-block;
  margin: 0;
  margin-right: 2%;
  width: 72.5%;
}

.app--questions .results button {
  display: inline-block;
  transform: translateY(2px);
  width: 25%;
}