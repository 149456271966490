.app--infinity .app--body {
  background-color: #9861ec;
  color: white;
  min-height: 100vh;
  padding: 6rem 2rem;
  text-align: center;
}

.app--infinity h1 {
  font-family: 'Berkshire Swash', cursive;
  font-size: 44px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.app--infinity h2 {
  font-size: 80px;
  margin-bottom: 0;
  margin-top: 0;
}

.app--infinity p::first-letter {
  font-family: 'Berkshire Swash', cursive;
  line-height: 1;
  margin-right: 2px;
  font-size: 150%;
}
